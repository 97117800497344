.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 100px;
}

.card:hover {
  transition: all 0.5s;
  border-color: var(--primaryDarkTransparent);
  box-shadow: 0 0.125rem 0.25rem var(--primaryDarkTransparent) !important;
}

.icon {
  fill: var(--primary);
}
